import React, { useContext } from 'react';

// GLOBAL VALUE
import { UserLanguages } from '@/ContextVal';

// STYLES
import styles from '@styles/about/About00Element.module.css';

// ASSETS
import AboutImg01 from '@img/about_img01.png';

const About00Element = () => {

    const { t } = useContext(UserLanguages);

    return (
        <div className={styles.container}>
            <div className={styles.innerWrap}>
                <div className={styles.titleWrap}>
                    <h3>
                        <span>{t('about_elm00_title01')}</span>
                        <br />{t('about_elm00_title02')}
                    </h3>
                </div>
                <div className={styles.imgWrap}>
                    <img src={AboutImg01} alt="상품 이미지 1" />
                </div>
                <div className={styles.infoWrap}>
                    <span>Info</span>
                    <h3>
                        {t('about_elm00_info01')}
                    </h3>
                    <p>
                        {t('about_elm00_info02')}
                    </p>
                </div>
            </div>
        </div>
    );
};

export default React.memo(About00Element);