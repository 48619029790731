import React, { useContext, useEffect, useState, useCallback } from 'react';

// GLOBAL VALUE
import { UserLanguages } from '@/ContextVal';

// COMPONENT
import { HomeSlides, CardItem } from '@/component/component';

// STYLES
import styles from '@styles/home/Home02Element.module.css';

// FUNCTION
import { calculate, progress, deviceType } from '@/calculate'

// ASSETS
import Device from '@img/device.png';

// VALUE
import { CardItemInfo } from '@component/home/homeCardInfo';

// TYPE
import { Props, HomeCardVal } from '@/typeList';


const elmAniVal:any= {};


const Home02Element = React.forwardRef(({componetState, prevElmHeight}:Props, ref:any) => {

    const { t } = useContext(UserLanguages);
    const [currentIndex, setCurrentIndex] = useState(-1);
    const [cardNum, setCardNum] = useState(-1);

    const setIndex = useCallback((index:number) => {
        return index === currentIndex ? true : false
    }, [currentIndex]);
    
    const setPlayCard = useCallback((index:number) => {
        return index === cardNum ? true : false
    }, [cardNum]);

    useEffect(() => {

        const valueConvert = () => {

            if (deviceType() === "PC" && window.innerWidth > 912) {
                elmAniVal.elm00 = { start: 0.18, end: 0.28, type: "boolean" };
                elmAniVal.elm01 = { start: 0.36, end: 0.46, type: "boolean" };
                elmAniVal.elm03 = { start: 0.54, end: 0.62, type: "boolean" };
                elmAniVal.elm04 = { start: 0.70, end: 0.80, type: "boolean" };
                elmAniVal.elm06 = { start: 0.86, end: 0.96, type: "boolean" };
            } else {
                elmAniVal.elm00 = { start: 0.20, end: 0.30, type: "boolean" };
                elmAniVal.elm01 = { start: 0.36, end: 0.46, type: "boolean" };
                elmAniVal.elm03 = { start: 0.59, end: 0.69, type: "boolean" };
                elmAniVal.elm04 = { start: 0.70, end: 0.80, type: "boolean" };
                elmAniVal.elm06 = { start: 0.95, end: 1.00, type: "boolean" };
            };
        };

        valueConvert();

        window.addEventListener('resize', valueConvert);

        return () => {
            window.removeEventListener('resize', valueConvert);
        };

    }, []);



    useEffect(() => {
        if (!componetState) return;

        const animation = () => {
           
            
            const useProgress = progress(ref, prevElmHeight);
            

            if (calculate(useProgress, elmAniVal.elm00)) {
                setCardNum(data => data = 0);
            } else if (calculate(useProgress, elmAniVal.elm01)) {
                setCardNum(data => data = 1);
            } else if (calculate(useProgress, elmAniVal.elm03)) {
                setCardNum(data => data = 3);
            } else if (calculate(useProgress, elmAniVal.elm04)) {
                setCardNum(data => data = 4);
            } else if (calculate(useProgress, elmAniVal.elm06)) {
                setCardNum(data => data = 6);
            } else {
                setCardNum(data => data = -1);
            };

        };

        if (componetState) {
            window.addEventListener('scroll', animation);
        } else {
            window.removeEventListener('scroll', animation);
        }

        return () => {
            // 언마운트
            window.removeEventListener('scroll', animation);
        };

    }, [componetState, cardNum, prevElmHeight]);

    return (
        <div 
          ref={ref} 
          className={styles.container}
        >
            <div className={styles.slidesWrap}>
                <div className={styles.text_wrap}>
                    <h3>{t('home_elm02_text01')}</h3>
                    <p>{t('home_elm02_text02')}</p>
                </div>
                <div className={styles.slides}>
                    <div className={styles.devive}>
                        <img src={Device} alt="휴대폰 목업" />
                    </div>
                    <HomeSlides />
                </div>
            </div>
            <div className={styles.cardWrap}>
                {CardItemInfo.map((item:HomeCardVal, index:number) => (
                    <CardItem
                      key={index}
                      content={item}
                      index={index}
                      isShow={setIndex(index)}
                      setCurrentIndex={setCurrentIndex}
                      cardNum={setPlayCard(index)}
                    />
                ))}
            </div>
        </div>
    );
});

export default React.memo(Home02Element);
