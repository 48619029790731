import React, { useContext } from 'react';

// GLOBAL VALUE
import { UserLanguages } from '@/ContextVal';


// STYLES
import styles from '@styles/common/CardItemChild.module.css';

const CardItemChild = (item:any) => {

    const { t, i18n } = useContext(UserLanguages);
    const CONTENT = item.item

    return (
        <div className={`${styles.container} child_wrap ${String(i18n.language).slice(0, 2) !== 'ko' ? styles.otherLang : ''}`}>
            {CONTENT.map((item:string, idx:number) => (
                <span key={idx} className={item}>{t(item)}</span>
            ))}
        </div>
    );
};

export default React.memo(CardItemChild);
