import React, { RefObject, useCallback, useRef, useState, useContext } from 'react';
import emailjs from '@emailjs/browser';

// GLOBAL VALUE
import { UserLanguages } from '@/ContextVal';

// STYLES
import styles from '@styles/about/ContactUs.module.css';

// TYPE
import { FormData } from '@/typeList';

interface Props {
  popupToggle: (message?:string, type?:string, elm?:any) => void;
  privacy: boolean;
  setPrivacy: React.Dispatch<React.SetStateAction<boolean>>;
}

const ContactUs = ({popupToggle, privacy, setPrivacy}:Props) => {

  const { t, i18n } = useContext(UserLanguages);
  const user_name:RefObject<HTMLInputElement> =useRef(null);
  const user_email:RefObject<HTMLInputElement> =useRef(null);
  const user_code:RefObject<HTMLSelectElement> =useRef(null);
  const user_contact:RefObject<HTMLInputElement> =useRef(null);
  const subject:RefObject<HTMLInputElement> =useRef(null);
  const message:RefObject<HTMLTextAreaElement> =useRef(null);

  const [formData, setFormData] = useState<FormData | any>({
    user_name: '',
    user_email: '',
    user_code: '+82',
    user_contact: '',
    subject: '',
    message: '',
  });

  const initPrivacy = useCallback(() => {
    setPrivacy(data => false);
  }, [setPrivacy])

  const editData = useCallback((
    e: React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
      | React.ChangeEvent<HTMLSelectElement>
  ) => {

    if (e.target.id === 'user_contact' && e.target.value.match('-') !== null) {
      popupToggle('about_alertMessage_07', '', user_contact.current);
      return;
    }

    setFormData((data: FormData) => {
      return { ...data, [e.target.id]: e.target.value };
    })
  }, [popupToggle])

  const resetData = useCallback(() => {
    setFormData({
      user_name: '',
      user_email: '',
      user_code: '+82',
      user_contact: '',
      subject: '',
      message: '',
    });
    initPrivacy();
  }, [initPrivacy])

  const sendEmail = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    for(let i = 0; i < Object.keys(formData).length; i++) {

      const key = Object.keys(formData)[i];
      const value = formData[key];


      if (value.length < 1) {
        let alertMessage:string | undefined;
        let elm: RefObject<
          HTMLInputElement |
          HTMLInputElement |
          HTMLSelectElement |
          HTMLTextAreaElement |
          undefined> = user_name;

        if (key === 'user_name') {
          alertMessage = 'about_alertMessage_01'; 
          elm = user_name;
        }
        if (key === 'user_email') {
          alertMessage = 'about_alertMessage_02';
          elm = user_email;
        }
        if (key === 'user_contact') {
          alertMessage = 'about_alertMessage_03';
          elm = user_contact;
        }
        if (key === 'subject') {
          alertMessage = 'about_alertMessage_04';
          elm = subject;
        }
        if (key === 'message') {
          alertMessage = 'about_alertMessage_05';
          elm = message;
        }

        popupToggle(`${alertMessage}`, '', elm.current);
        return;
      };
      
    };

    if (!privacy) {
      popupToggle('about_alertMessage_06');
      return;
    };

    emailjs.send('service_hsumt2r', 'template_s1wue7j', formData, 'Xtm65H29okUjBLupc')
      .then((result) => {
        popupToggle('about_alertMessage_08');
          console.log(result.text);
          resetData();
      }, (error) => {
          resetData();
          popupToggle('about_alertMessage_09');
          console.log(error.text);
      });
  };

  return (
    <form 
      className={styles.container} 
      onSubmit={sendEmail}
    >
      <h3 className={styles.title}>{t('about_contact_title')} </h3>
      <div className={`${styles.inputWrap} ${styles.userName}`}>
        <label 
          htmlFor="user_name"
          className={styles.inputLabel}
        >
          {t('about_contact_1')} 
        </label>
        <input
          ref={user_name}
          type="text"
          id="user_name"
          name="user_name"
          placeholder={t('about_alertMessage_01')}
          value={formData.user_name}
          onChange={editData}
        />
      </div>
      <div className={`${styles.inputWrap} ${styles.userEmail}`}>
        <label 
          htmlFor="user_email"
          className={styles.inputLabel}
        >
          {t('about_contact_2')} 
        </label>
        <input
          ref={user_email}
          type="email"
          id="user_email"
          name="user_email"
          placeholder={t('about_alertMessage_02')}
          value={formData.user_email}
          onChange={editData}
        />
      </div>
      <div className={`${styles.inputWrap} ${styles.userContact}`}>
        <label 
          htmlFor="user_contact"
          className={styles.inputLabel}
        >
          {t('about_contact_3')} 
        </label>
        <div>
          <select
            ref={user_code}
            name="user_code" 
            id="user_code" 
            value={formData.user_int} 
            onChange={editData}
          >
            <option value={'+82'}>+82</option>
            <option value={'+81'}>+81</option>
            <option value={'+1'}>+1</option>
          </select>
          <input
            ref={user_contact}
            type="tel"
            id="user_contact"
            name="user_contact"
            placeholder={t('about_alertMessage_07')}
            value={formData.user_contact}
            onChange={editData}
          />
        </div>
      </div>
      <div className={`${styles.inputWrap} ${styles.userSubject}`}>
        <label 
          htmlFor="subject"
          className={styles.inputLabel}
        >
          {t('about_contact_4')} 
        </label>
        <input
          ref={subject}
          type="text"
          id="subject"
          name="subject"
          placeholder={t('about_alertMessage_04')}
          value={formData.subject}
          onChange={editData}
        />
      </div>
      <div className={`${styles.inputWrap} ${styles.userMessage}`}>
        <label 
          htmlFor="message"
          className={styles.inputLabel}
        >
          {t('about_contact_5')} 
        </label>
        <textarea
          ref={message}
          id="message"
          name="message"
          value={formData.message}
          onChange={editData}
        />
      </div>
      <div className={`${styles.inputWrap} ${styles.submit}`}>
        <div className={styles.privacyWrap}>
          <input
            type="checkbox"
            id="checkbox"
            name="checkbox"
            checked={privacy}
            onChange={() => {
              if (String(i18n.language).slice(0, 2) !== 'ko') {
                setPrivacy(current => current = !current)
              } else {
                popupToggle('', 'PRIVACY');
              }
            }}
          />
          <label htmlFor="checkbox">{t('about_contact_6')} </label>
        </div>
        <input 
          className={styles.submitBtn}
          type="submit" 
          value={t('about_contact_7')} 
        />
      </div>
    </form>
  );
};

export default ContactUs;