import React, { useContext } from 'react';

// GLOBAL VALUE
import { UserLanguages } from '@/ContextVal';

// // STYLES
import styles from '@styles/about/About02Element.module.css';

const HistoryInfo = [
    {
        year: '2007',
        text: 'about_history01'
    },
    {
        year: '2008',
        text: 'about_history02'
    },
    {
        year: '2009',
        text: 'about_history03'
    },
    {
        year: '2010',
        text: 'about_history04'
    },
    {
        year: '2013',
        text: 'about_history05'
    },
    {
        year: '2014',
        text: 'about_history06'
    },
    {
        year: '2016',
        text: 'about_history07'
    },
    {
        year: '2017',
        text: 'about_history08'
    },
    {
        year: '2018',
        text: 'about_history09'
    },
    {
        year: '2022',
        text: 'about_history10'
    }
]


const About02Element = () => {

    const { t } = useContext(UserLanguages);

    return (
        <div className={styles.container}>
            <div className={styles.title}>
                <h3>
                    {t('about_history_title')}
                </h3>
                <h4 className={styles.upToDateMoblie}>
                    {t('about_history_uptodate')}
                </h4>
            </div>
            <div className={styles.historyWrap}>
                <div className={styles.upToDate}>
                    <h4>
                        {t('about_history_uptodate')}
                    </h4>
                </div>
                <ul>
                    {HistoryInfo.map((item, idx) => (
                        <li key={idx}>
                            <div className={styles.year}>
                                <h4>
                                    {item.year}
                                </h4>
                            </div>
                            <div className={styles.content}>
                                <p>
                                    {t(`${item.text}`)}
                                </p>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default React.memo(About02Element);