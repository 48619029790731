import React, { useContext, useEffect, useState } from 'react';

// GLOBAL VALUE
import { UserLanguages } from '@/ContextVal';

// STYLES
import styles from '@styles/home/Home01Element.module.css';

// FUNCTION
import { calculate, progress } from '@/calculate'

// TYPE
import { Props } from '@/typeList';


const Home01Element = React.forwardRef(({componetState, prevElmHeight}:Props, ref:any) => {

    const { t } = useContext(UserLanguages);

    const elmAniVal:any = {
        elm00_OP_in: { start: 0.1, end: 0.5, type: "ease-in-out", min: 0, max: 1  },
        elm01_BR_in: { start: 0.1, end: 0.8, type: "ease-in-out", min: 10, max: 0  },
    };

    const [renderElm, setRenderElm] = useState({
        elm00_OP: 0,
        elm01_BR: 0,
    });

    let useProgress = 0;

    useEffect(() => {
        if (!componetState) return;

        const animation = () => {

            useProgress = progress(ref, prevElmHeight);

            if (useProgress < elmAniVal.elm00_OP_in.end) {
                setRenderElm((data) => {
                    return { ...data, elm00_OP: calculate(useProgress, elmAniVal.elm00_OP_in) };
                });
            } else if (prevElmHeight > window.pageYOffset || prevElmHeight === 0) {
                setRenderElm((data) => {
                    return { ...data, elm00_OP: 0 };
                });
            } else if ((useProgress > elmAniVal.elm00_OP_in.end && prevElmHeight < window.pageYOffset) || prevElmHeight > 0 ) {
                setRenderElm((data) => {
                    return { ...data, elm00_OP: 1 };
                });
            };


            if (useProgress < elmAniVal.elm01_BR_in.end) {
                setRenderElm((data) => {
                    return { ...data, elm01_BR: calculate(useProgress, elmAniVal.elm01_BR_in) };
                });
            } else {
                setRenderElm((data) => {
                    return { ...data, elm01_BR: 0 };
                });
            };

        };


        if(componetState) {
            window.addEventListener('scroll', animation);
        } else {
            window.removeEventListener('scroll', animation);
        }


        return () => {
            // 언마운트
            window.removeEventListener('scroll', animation);
        }

    }, [componetState, prevElmHeight]);



    return (
        <div 
          ref={ref} 
          className={styles.container}
        >
            <div className={styles.innetWrap}>
                <h3 style={{
                    opacity: `${renderElm.elm00_OP.toFixed(3)}`, 
                    filter:`blur(${renderElm.elm01_BR}px)`
                    }}
                >
                    {t('home_elm01_text01')}
                </h3>
            </div>
        </div>
    );
});

export default React.memo(Home01Element);
