import React, { useContext } from 'react';

// GLOBAL VALUE
import { UserLanguages } from '@/ContextVal';

// COMPONENT
import { SpecsTable } from '@/component/component';

// STYLES
import styles from '@styles/specs/Specs01Element.module.css';
import { t } from 'i18next';

const specsListInfo = [
    {
        name: 'specs_list00_name',
        text: ['specs_list00_text01'],
        type: 'TEXT',
    },
    {
        name: 'specs_list01_name',
        text: ['specs_list01_text01', 
               'specs_list01_text02', 
               'specs_list01_text03'],
        type: 'TEXT',
    },
    {
        name: 'specs_list02_name',
        text: ['specs_list02_text01'],
        type: 'TEXT',
    },
    {
        name: 'specs_list03_name',
        text: ['specs_list03_text01'],
        type: 'TEXT',
    },
    {
        name: 'specs_list04_name',
        text: ['specs_list04_text01'],
        type: 'TEXT',
    },
    {
        name: 'specs_list05_name',
        type: 'ELEMENT',
    }
]

const SpecsTextChild = (item:any) => {
    const Item = item.item;

    const textRender = () => {
        let render;
        if (Item.length === 3) {
            render = (
                <div>
                    <p>{t<string>(`${Item[0]}`)}</p>
                    <span>{t<string>(`${Item[1]}`)}</span>
                    <p>{t<string>(`${Item[2]}`)}</p>
                </div>
            )
        } else {
            render = <p>{t<string>(`${Item[0]}`)}</p>;
        };
        return render;
    }

    return (
        <div>
            {textRender()}
        </div>
    );
};


const Specs01Element = () => {

    const { t } = useContext(UserLanguages);

    return (
        <div className={styles.container}>
            <ul>
                {specsListInfo.map((item, idx) => (
                    <li key={idx} className={styles.specsList}>
                        <div className={styles.listName}>
                            <h3>
                                {t(`${item.name}`)}
                            </h3>
                        </div>
                        <div className={styles.listText}>
                            {(item.type === 'TEXT') ?
                                <SpecsTextChild item={item.text} /> : <SpecsTable />
                            }
                        </div>
                    </li>
                ))}

            </ul>
        </div>
    );
};

export default React.memo(Specs01Element);