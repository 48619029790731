import React, { useEffect, useState, useRef, RefObject, useCallback } from 'react'

// COMPONENT
import { Home00Element, Home01Element, Home02Element } from '@/component/component';

// STYLES
import styles from '@styles/home/Home.module.css';


const Home = () => {
    
    const [currentScene, setCurrentScene] = useState(0);
    const [prevElmHeight, setPrevElmHeight] = useState(0);

    const homeRef:RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);
    const visual00ElementRef:RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);
    const visual01ElementRef:RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);
    const visual02ElementRef:RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);
    const elmInfo:Array<any> = [visual00ElementRef, visual01ElementRef, visual02ElementRef];

    const currentState = useCallback((data:number) => {
        return data === currentScene ? true : false;
    }, [currentScene]);

    useEffect(() => {

        return () => {
            window.scrollTo(0, 0);
        };
    }, []);


    useEffect(() => {

        const userCurrentScene = () => {
            let totalElmHeight = 0;

            for (let i = 0; i < elmInfo.length; i++) {
                totalElmHeight += elmInfo[i].current?.clientHeight;
                if (totalElmHeight >= window.pageYOffset) {
                    setCurrentScene(i);
                    break;
                };
            };

        };

        const getPrevElmHeight = () => {
            let prevVal = 0;
            
            for (let i = 0; i < currentScene; i++) {
                prevVal += elmInfo[i].current?.clientHeight;
            }
            setPrevElmHeight(data => prevVal);

        }
        
        const onScroll = () => {
            
            userCurrentScene();
            getPrevElmHeight();
        };


        window.addEventListener('scroll', onScroll);

        //언마운트
        return () => {
            window.removeEventListener('scroll', onScroll);
        }

    }, [currentScene, prevElmHeight]);


    return (
        <div
            ref={homeRef}
            className={styles.container}
        >
            <Home00Element
                ref={visual00ElementRef}
                componetState={currentState(0)}
                prevElmHeight={prevElmHeight}
            />
            <Home01Element
                ref={visual01ElementRef}
                componetState={currentState(1)}
                prevElmHeight={prevElmHeight}
            />
            <Home02Element
                ref={visual02ElementRef}
                componetState={currentState(2)}
                prevElmHeight={prevElmHeight}
            />
        </div>
    );
};

export default React.memo(Home);