import React, { RefObject, useEffect, useRef, useCallback, useContext } from 'react'

// GLOBAL VALUE
import { UserLanguages } from '@/ContextVal';

// STYLES
import styles from '@styles/common/CustomPopup.module.css';

//TYPE
interface Props {
  isPopup:boolean;
  popupToggle: (message?:string, type?:string, elm?:any) => void;
  alertMessage: string;
  setPrivacy: React.Dispatch<React.SetStateAction<boolean>>;
  popupType:string;
  focusRef: any;
}

const CustomPopup = ({isPopup, popupToggle, alertMessage, setPrivacy, popupType, focusRef}:Props) => {

  const { t } = useContext(UserLanguages);
  const popupContainer:RefObject<HTMLDivElement> = useRef(null);
  const popupItem:RefObject<HTMLDivElement> = useRef(null);

  const isPrivacy = useCallback(() => {
    setPrivacy(data => true);
    popupToggle('', 'PRIVACY')
  }, [popupToggle, setPrivacy]);

  const notPrivacy = useCallback(() => {
    setPrivacy(data => false);
    popupToggle('', 'PRIVACY')
  }, [popupToggle, setPrivacy]);

  useEffect(() => {
    const userScroll = () => {
      if (!popupContainer.current || !popupItem.current) return;

      if (isPopup) {
        popupContainer.current.style.display = 'flex';
        document.documentElement.style.overflow = 'hidden';
        setTimeout(() => {
          if (!popupContainer.current || !popupItem.current) return;
          popupContainer.current.style.opacity = '1';
          popupItem.current.style.display = '1';
        }, 100);

      } else {
        document.documentElement.style.overflow = '';
        popupContainer.current.style.opacity = '0';
        popupItem.current.style.display = '0';
        setTimeout(() => {
          if (!popupContainer.current) return;
          popupContainer.current.style.display = 'none';
        }, 600);

        if(!focusRef) return;
        focusRef.focus();
      };

    };

    userScroll();

  }, [isPopup, focusRef])
  
  return (
    <div
      className={styles.container}
      ref={popupContainer}
    >
      {
        (popupType === 'PRIVACY')
          ?
          <div className={styles.privacyWrap} ref={popupItem}>
            <div className={styles.privacyInnerWrap}>
              <div className={styles.privacyTextWrap}>
                <div className={styles.privacyH2Wrap}>
                  <h2>{t('privacy01_h2')}</h2>
                  <p>{t('privacy01_p')}</p>
                </div>
                <div className={styles.privacyH3Wrap}>
                  <h3>{t('privacy02_h3')}</h3>
                  <p>{t('privacy02_p')}</p>
                </div>
                <div className={styles.privacyH3Wrap}>
                  <h3>{t('privacy03_h3')}</h3>
                  <p>{t('privacy03_p')}</p>
                </div>
                <div className={styles.privacyH3Wrap}>
                  <h3>{t('privacy04_h3')}</h3>
                  <p>{t('privacy04_p')}</p>
                  <span>{t('privacy04_span1')}</span>
                  <span>{t('privacy04_span2')}</span>
                </div>
                <div className={styles.privacyH3Wrap}>
                  <h3>{t('privacy05_h3')}</h3>
                  <p>{t('privacy05_p')}</p>
                  <span>{t('privacy05_span1')}</span>
                  <span>{t('privacy05_span2')}</span>
                  <span>{t('privacy05_span3')}</span>
                  <span>{t('privacy05_span4')}</span>
                </div>
                <div className={styles.privacyH3Wrap}>
                  <h3>{t('privacy06_h3')}</h3>
                  <p>{t('privacy06_p')}</p>
                  <span>{t('privacy06_span1')}</span>
                  <span>{t('privacy06_span2')}</span>
                  <span>{t('privacy06_span3')}</span>
                  <span>{t('privacy06_span4')}</span>
                </div>
                <div className={styles.privacyH3Wrap}>
                  <h3>{t('privacy07_h3')}</h3>
                  <p>{t('privacy07_p')}</p>
                  <span>{t('privacy07_span1')}</span>
                  <span>{t('privacy07_span2')}</span>
                  <span>{t('privacy07_span3')}</span>
                </div>
                <div className={styles.privacyH3Wrap}>
                  <h3>{t('privacy08_h3')}</h3>
                  <p>{t('privacy08_p')}</p>
                  <span>{t('privacy08_span1')}</span>
                  <span>{t('privacy08_span2')}</span>
                  <span>{t('privacy08_span3')}</span>
                  <span>{t('privacy08_span4')}</span>
                  <span>{t('privacy08_span5')}</span>
                  <span>{t('privacy08_span6')}</span>
                </div>
                <div className={styles.privacyH3Wrap}>
                  <h3>{t('privacy09_h3')}</h3>
                  <p>{t('privacy09_p1')}</p>
                  <p>{t('privacy09_p2')}</p>
                  <span>{t('privacy09_span1')}</span>
                  <span>{t('privacy09_span2')}</span>
                  <span>{t('privacy09_span3')}</span>
                  <span>{t('privacy09_span4')}</span>
                </div>
                <div className={styles.privacyH3Wrap}>
                  <h3>{t('privacy10_h3')}</h3>
                </div>
                <div className={styles.privacyH3Wrap}>
                  <h3>{t('privacy11_h3')}</h3>
                  <p>{t('privacy11_p')}</p>
                  <span>{t('privacy11_span1')}</span>
                  <span>{t('privacy11_span2')}</span>
                  <span>{t('privacy11_span3')}</span>
                  <span>{t('privacy11_span4')}</span>
                </div>
              </div>
              <div className={styles.privacyButtonWrap}>
                <button 
                  className={styles.privacySubmit}
                  onClick={() => isPrivacy()}
                >
                  {t('privacy_btn1')}
                </button>
                <button
                  className={styles.privacyClose}
                  onClick={() => notPrivacy()}
                >
                  {t('privacy_btn2')}
                </button>
              </div>
            </div>
          </div>
          :
          <div className={styles.popupWrap} ref={popupItem}>
            <div className={styles.innerWrap}>
              <div className={styles.textWrap}>
                <p>{t(`${alertMessage}`)}</p>
              </div>
              <div className={styles.buttonWrap}>
                <button onClick={() => popupToggle()}>
                  확인
                </button>
              </div>
            </div>
          </div>
      }
    </div>
  )
};

export default React.memo(CustomPopup);