import React, { useContext, useCallback } from 'react'

// LANGUAGES
import { Languages, languages } from '@i18n/i18n'

// GLOBAL VALUE
import { UserLanguages } from '@/ContextVal';

// STYLES
import styles from '@styles/common/LanguagesBtn.module.css';

// FUNCTION
import { deviceType } from '@/calculate'

//TYPE
interface Props {
  isHover: boolean;
  onMobileMenu: () => void
}

const LanguagesBtn = ({ isHover, onMobileMenu }: Props) => {

  const { t, i18n } = useContext(UserLanguages);

  const handleChangeLanguage = useCallback((lang: Languages) => {
    i18n.changeLanguage(lang);
    const titleElement = document.getElementsByTagName('title')[0];
    if (lang === 'jp') {
      titleElement.innerHTML = '熱ちゃん';
    } else if (lang === 'en') {
      titleElement.innerHTML = 'YeolJaeYo';
    } else if (lang === 'ko') {
      titleElement.innerHTML = '열재요';
    }

  }, [i18n]);

  return (
    <div
      className={styles.container}
      style={{ opacity: isHover ? 1 : 0 }}
    >
      {languages.map(lang => (
        <button
          className={`language_${lang} ${String(i18n.language).slice(0, 2) === lang ? styles.active : ''}`}
          key={lang}
          onClick={() => {
            handleChangeLanguage(lang);
            if (deviceType() === 'MO' && window.innerWidth < 912) onMobileMenu();
          }}
        >
          <span>{t(`language_${lang}`)}</span>
        </button>
      ))}
    </div>
  );
};

export default React.memo(LanguagesBtn);