import React, { useEffect } from 'react';

// COMPONENT
import { Specs00Element, Specs01Element } from '@/component/component';

// STYLES
import styles from '@styles/specs/Specs.module.css';

const Specs = () => {

    useEffect(() => {

        // 언마운트
        return () => {
            window.scrollTo(0, 0);
        }
    },[]);

    return (
        <div className={styles.container}>
            <div className={styles.innerWrap}>
                <Specs00Element />
                <Specs01Element />
            </div>
        </div>
    );
};

export default React.memo(Specs);