import React, { useContext } from 'react';

// GLOBAL VALUE
import { UserLanguages } from '@/ContextVal';

// STYLES
import styles from '@styles/specs/Specs00Element.module.css';

// ASSETS
import SpecsImg01 from '@img/specs_img01.jpg';
import SpecsImg02 from '@img/specs_img02.jpg';

const Specs00Element = () => {

    const { t } = useContext(UserLanguages);

    return (
        <div className={styles.container}>
            <div className={styles.titleWrap}>
                <h3>{t('specs_elm_title')}</h3>
            </div>
            <div className={styles.imgWrap}>
                <img src={SpecsImg01} alt="상품 이미지 1" />
                <img src={SpecsImg02} alt="상품 이미지 2" />
            </div>
        </div>
    );
};

export default React.memo(Specs00Element);