import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import tranKo from '@i18n/locales/ko.json';
import tranJp from '@i18n/locales/jp.json';
import tranEn from '@i18n/locales/en.json';



const resources = {
  ko: { translation: tranKo },
  jp: { translation: tranJp },
  en: { translation: tranEn },
}

const userLanguage = window.navigator.language;

i18n.use(initReactI18next).init({
  resources,
  lng: localStorage.getItem('language') || userLanguage || 'en',
  fallbackLng: 'en',
  keySeparator: false,
  interpolation: {
    escapeValue: false
  }
})

export default i18n;

export const languages = [ 'ko', 'jp', 'en' ] as const;
export type Languages = typeof languages[number];