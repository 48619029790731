import { useCallback, useState} from 'react';
import { Routes, Route } from 'react-router-dom';
import { Home, About, Specs, NotFound } from '@/pages/pages';

// LANGUAGES
import { useTranslation } from 'react-i18next';

// GLOBAL VALUE
import { UserLanguages } from '@/ContextVal';

// COMPONENT
import { Header, Footer, CustomPopup } from '@/component/component';

function App() {

  const { t, i18n } = useTranslation();
  const [isPopup, setIsPopup] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>('');
  const [privacy, setPrivacy] = useState<boolean>(false);
  const [popupType, setPopupType] = useState<string>('');
  const [focusRef, setFocusRef] = useState();

  const popupToggle = useCallback((message?: string, type?: string, elm?:any) => {
    setIsPopup(data => !data);

    if (typeof (message) === 'string') {
      setAlertMessage(data => data = message);
    };

    if (type === 'PRIVACY') {
      setPopupType(data => data = type);
    } else {
      setPopupType(data => data = '');
    };

    if(elm) {
      setFocusRef(data => data = elm);
    }
  }, [])

  return (
      <UserLanguages.Provider value={{t, i18n}}>
        <div className="App">
          <CustomPopup
            isPopup={isPopup}
            popupToggle={popupToggle}
            alertMessage={alertMessage}
            setPrivacy={setPrivacy}
            popupType={popupType}
            focusRef={focusRef}
          />
          <Header />
          <Routes>
            <Route path='/' element={<Home />} />
            <Route
              path='/about'
              element={
                <About
                  popupToggle={popupToggle}
                  privacy={privacy}
                  setPrivacy={setPrivacy}
                />
              }
            />
            <Route path='/specs' element={<Specs />} />
            <Route path='*' element={<NotFound />} />
          </Routes>
          <Footer />
        </div>
      </UserLanguages.Provider>
  );
}

export default App;
