import React, { useContext } from 'react'

// GLOBAL VALUE
import { UserLanguages } from '@/ContextVal';

// STYLES
import styles from '@styles/common/Footer.module.css';


const Footer = () => {

    const { t, i18n } = useContext(UserLanguages);

    return (
        <footer 
          className={`${styles.container} ${String(i18n.language).slice(0, 2) !== 'ko' ? styles.otherLang : ''}`}
        >
            <div className={styles.innerWrap}>
                <div className={styles.company}>
                    <p className={`${styles.company01} ${styles.contnetTitle}`}>
                        {t(`footer_company`)}
                    </p>
                    <div className={styles.contentWrap}>
                        <p className={styles.company02}>
                            {t(`footer_ceo_name`)}
                        </p>
                        <p className={styles.company03}>
                            {t(`footer_business_number`)}
                            &nbsp;
                            &nbsp;
                            <a 
                              href="http://www.ftc.go.kr/info/bizinfo/communicationViewPopup.jsp?wrkr_no=8178701941" 
                              target="_blink">
                                {t(`footer_business_number_link`)}
                            </a>
                        </p>
                    </div>
                </div>
                <div className={styles.address}>
                    <p className={`${styles.address01} ${styles.contnetTitle}`}>
                        {t(`footer_address`)}
                    </p>
                    <address>
                        {t(`footer_address_content`)}
                    </address>
                </div>
                <div className={styles.service}>
                    <p className={`${styles.service01} ${styles.contnetTitle}`}>
                        {t(`footer_service`)}
                    </p>
                    <div className={styles.contentWrap}>
                        <address className={styles.service02}>Tel .031-337-1020</address>
                        <address className={styles.service03}>Fax. 031-337-1021</address>
                        <address>E-mail. hottech@hot-tech.co.kr</address>
                    </div>
                </div>
                <div className={styles.copyright}>
                    <p>
                        {t(`footer_copyright`)}
                    </p>
                </div>
            </div>
        </footer>
    );
};

export default React.memo(Footer);