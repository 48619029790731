import React, { useEffect } from 'react'

// COMPONENT
import { About00Element, About01Element, About02Element, ContactUs, } from '@/component/component';

// STYLES
import styles from '@styles/about/About.module.css';

interface Props {
    popupToggle: (message?:string) => void;
    privacy: boolean;
    setPrivacy: React.Dispatch<React.SetStateAction<boolean>>;
  }

const About = ({ popupToggle, privacy, setPrivacy }:Props) => {

    useEffect(() => {

        // 언마운트
        return () => {
            window.scrollTo(0, 0);
        };
    }, []);

    return (
        <div className={styles.container}>
            <About00Element />
            <About01Element />
            <About02Element />
            <ContactUs popupToggle={popupToggle} privacy={privacy} setPrivacy={setPrivacy}/>
        </div>
    );
};

export default React.memo(About);