// ASSETS
import Card01Media from '@video/card01_media.mp4';
import Card01MediaMO from '@video/card01_media_m.mp4';
import Card02Media from '@video/card02_media.mp4';
import Card03Media from '@img/card03_media.png';
import Card04Media from '@video/card04_media.mp4';
import Card04MediaMO from '@video/card04_media_m.mp4';
import Card05Media from '@video/card05_media.mp4';
import Card06Media from '@img/card06_media.png';
import Card07Media from '@video/card07_media.mp4';
import Card07MediaMO from '@video/card07_media_m.mp4';

import Card01Poster from '@img/cardPoster01.jpg';
import Card01PosterMO from '@img/cardPoster01_m.webp';
import Card02Poster from '@img/cardPoster02.jpg';
import Card04Poster from '@img/cardPoster04.jpg';
import Card04PosterMO from '@img/cardPoster04_m.webp';
import Card05Poster from '@img/cardPoster05.jpg';
import Card07Poster from '@img/cardPoster07.jpg';
import Card07PosterMO from '@img/cardPoster07_m.jpg';

// TYPE
import { HomeCard } from '@/typeList';



export const CardItemInfo:HomeCard = [
    {
        className: 'home_card01',
        textContent:
            [['home_card01_title'], [
                ['home_card01_title'],
                ['home_card01_sub_tit01', 'home_card01_text01'],
                ['home_card01_sub_tit02', 'home_card01_text02'],
                ['home_card01_sub_tit03', 'home_card01_text03'],
                ['home_card01_sub_tit04', 'home_card01_text04']
            ]],
        mediaType: 'VIDEO',
        mediaPC: [Card01Media, Card01Poster],
        mediaMO: [Card01MediaMO, Card01PosterMO],
    },
    {
        className: 'home_card02',
        textContent:
            [['home_card02_title', 'home_card02_desc'], [
                ['home_card02_title'],
                ['home_card02_sub_tit01', 'home_card02_text01']
            ]],
        mediaType: 'VIDEO',
        mediaPC: [Card02Media, Card02Poster],
        mediaMO: [Card02Media, Card02Poster],
    },
    {
        className: 'home_card03',
        textContent:
            [['home_card03_title', 'home_card03_desc'], [
                ['home_card03_title'],
                ['home_card03_sub_tit01', 'home_card03_text01']
            ]],
        mediaType: 'IMAGE',
        mediaPC: [Card03Media],
        mediaMO: [Card03Media],
    },
    {
        className: 'home_card04',
        textContent:
            [['home_card04_title'], [
                ['home_card04_title'],
                ['home_card04_sub_tit01', 'home_card04_text01']
            ]],
        mediaType: 'VIDEO',
        mediaPC: [Card04Media, Card04Poster],
        mediaMO: [Card04MediaMO, Card04PosterMO],
    },
    {
        className: 'home_card05',
        textContent:
            [['home_card05_title'], [
                ['home_card05_title'],
                ['home_card05_text01']
            ]],
        mediaType: 'VIDEO',
        mediaPC: [Card05Media, Card05Poster],
        mediaMO: [Card05Media, Card05Poster],
    },
    {
        className: 'home_card06',
        textContent:
            [['home_card06_title'], [
                ['home_card06_title'],
                ['home_card06_text01']
            ]],
        mediaType: 'IMAGE',
        mediaPC: [Card06Media],
        mediaMO: [Card06Media],
    },
    {
        className: 'home_card07',
        textContent:
            [['home_card07_title'], [
                ['home_card07_title'],
                ['home_card07_sub_tit01', 'home_card07_text01'],
                ['home_card07_sub_tit02', 'home_card07_text02']
            ]],
        mediaType: 'VIDEO',
        mediaPC: [Card07Media, Card07Poster],
        mediaMO: [Card07MediaMO, Card07PosterMO],
    },
]