// TYPE
type DOM = any;


interface Val {
    start: number;
    end: number;
    type: string;
    min: number;
    max: number;
}

export const progress = (elm:DOM, prevH:number) => {
    let progress = 0;
    if (!elm.current) return progress;
    if (window.pageYOffset >= prevH )
    progress = Math.min((window.pageYOffset - prevH) / (elm.current?.clientHeight - window.innerHeight), 1);

    return progress;
}


export const calculate = (process:number, values:Val) => {

    let rv:any;
    const partScrollStart = values.start;
    const partScrollEnd = values.end;
    const partScrollHeight = partScrollEnd - partScrollStart;
    const partRatio = (process - partScrollStart) / partScrollHeight;

    switch (values.type) {
        case 'ease-in-out':
            if (process >= partScrollStart && process <= partScrollEnd) {
                rv = (easeInOutSine(partRatio) * (values?.max - values?.min) + values?.min);
            } else if (process < partScrollStart) {
                rv = values.min;
            } else if (process > partScrollEnd) {
                rv = values.max;
            }
            break;

        case 'boolean':
            if (process >= partScrollStart && process <= partScrollEnd) {
                rv = true;
            } else if (process < partScrollStart) {
                rv = false;
            } else if (process > partScrollEnd) {
                rv = false;
            }
            break;
        default:
            rv = 0;
            break;

    }

    function easeInOutSine(x:number) {
        return -(Math.cos(Math.PI * x) - 1) / 2;
    }

    return rv;
}

export const deviceType = () => {
    const user = navigator.userAgent;
    let type:string;
  
    if (user.indexOf("iPhone") > -1
        || user.indexOf("Android") > -1
        || user.indexOf("iPad") > -1
    ) {
     return type = "MO"
    } else {
     return type = "PC"
    }
  
  }
