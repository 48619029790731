import { BrowserRouter } from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import '@styles/common/reset.css';
import '@styles/common/global.css';
import App from '@/App';

import '@i18n/i18n';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
    <BrowserRouter>
      <App />
    </BrowserRouter>
);

