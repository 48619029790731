import React, { useContext, useEffect, useState } from 'react';

// GLOBAL VALUE
import { UserLanguages } from '@/ContextVal';

// STYLES
import styles from '@styles/specs/SpecsTable.module.css';

// FUNCTION
import { deviceType } from '@/calculate'

const SpecsTable = () => {

    const { t } = useContext(UserLanguages);
    const [isMobile, setIsMobile] = useState(true);

    useEffect(() => {
        const getDeviceType = () => {

            if (deviceType() === 'PC' && window.innerWidth > 912) {
                setIsMobile(true);
            } else {
                setIsMobile(false);
            }
        }

        getDeviceType();

        window.addEventListener('resize', getDeviceType);

        return () => {
            window.removeEventListener('resize', getDeviceType);
        }

    }, []);

    return (
        <table className={styles.container}>
            <caption>
                {t(`specs_table_caption`)}
            </caption>
            <colgroup>
                <col width={isMobile ? '17.2297%' : '16.6666%'} />
                <col width={isMobile ? '17.2297%' : '23.0769%%'} />
                <col width={isMobile ? '32.77%' : '30.44871%'} />
                <col width={isMobile ? '32.77%' : '30.44871%'} />
            </colgroup>
            <tbody>
                <tr className={styles.R1}>
                    <td className={styles.C1} colSpan={2}>
                        {t(`specs_table_r01_c01`)}
                    </td>
                    <td className={styles.C2} colSpan={2}>
                        {t(`specs_table_r01_c02`)}
                    </td>
                </tr>
                <tr className={styles.R2}>
                    <td className={styles.C1} rowSpan={10}>
                        {t(`specs_table_r02_c01`)}
                    </td>
                    <td className={styles.C2}>
                        {t(`specs_table_r02_c02`)}
                    </td>
                    <td className={styles.C3} colSpan={2}>
                        {t(`specs_table_r02_c03`)}
                    </td>
                </tr>
                <tr className={styles.R3}>
                    <td className={styles.C2}>
                        {t(`specs_table_r03_c02`)}
                    </td>
                    <td className={styles.C3} colSpan={2}>
                        {t(`specs_table_r03_c03`)}
                    </td>
                </tr>
                <tr className={styles.R4}>
                    <td className={styles.C2}>
                        {t(`specs_table_r04_c02`)}
                    </td>
                    <td className={styles.C3} colSpan={2}>
                        {t(`specs_table_r04_c03`)}
                    </td>
                </tr>
                <tr className={styles.R5}>
                    <td className={styles.C2}>
                        {t(`specs_table_r05_c02`)}
                    </td>
                    <td className={styles.C3} colSpan={2}>
                        {t(`specs_table_r05_c03`)}
                    </td>
                </tr>
                <tr className={styles.R6}>
                    <td className={styles.C2}>
                        {t(`specs_table_r06_c02`)}
                    </td>
                    <td className={styles.C3} colSpan={2}>
                        {t(`specs_table_r06_c03`)}
                    </td>
                </tr>
                <tr className={styles.R7}>
                    <td className={styles.C2}>
                        {t(`specs_table_r07_c02`)}
                    </td>
                    <td className={styles.C3}>
                        {t(`specs_table_r07_c03`)}
                    </td>
                    <td className={styles.C4}>
                        {t(`specs_table_r07_c04`)}
                    </td>
                </tr>
                <tr className={styles.R8}>
                    <td className={styles.C2}>
                        {t(`specs_table_r08_c02`)}
                    </td>
                    <td className={styles.C3}>
                        {t(`specs_table_r08_c03`)}
                    </td>
                    <td className={styles.C4}>
                        {t(`specs_table_r08_c04`)}
                    </td>
                </tr>
                <tr className={styles.R9}>
                    <td className={styles.C2}>
                        {t(`specs_table_r09_c02`)}
                    </td>
                    <td className={styles.C3} colSpan={2}>
                        {t(`specs_table_r09_c03`)}
                    </td>
                </tr>
                <tr className={styles.R10}>
                    <td className={styles.C2}>
                        {t(`specs_table_r10_c02`)}
                    </td>
                    <td className={styles.C3} colSpan={2}>
                        {t(`specs_table_r10_c03`)}
                    </td>
                </tr>
                <tr className={styles.R11}>
                    <td className={styles.C2}>
                        {t(`specs_table_r11_c02`)}
                    </td>
                    <td className={styles.C3} colSpan={2}>
                        {t(`specs_table_r11_c03`)}
                    </td>
                </tr>
                <tr className={styles.R12}>
                    <td className={styles.C2} colSpan={2}>
                        {t(`specs_table_r12_c02`)}
                    </td>
                    <td className={styles.C3} colSpan={2}>
                        {t(`specs_table_r12_c03`)}
                    </td>
                </tr>
            </tbody>
        </table>
    );
};

export default React.memo(SpecsTable);