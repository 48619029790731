import React, { useContext, useEffect, useState, useRef, RefObject, useCallback } from 'react'
import { Link, useLocation } from 'react-router-dom';

// GLOBAL VALUE
import { UserLanguages } from '@/ContextVal';

// COMPONENT
import { LanguagesBtn } from '@/component/component';

// STYLES
import styles from '@styles/common/Header.module.css';

// ASSETS
import Logo from '@img/logo.svg';
import LogoMobile from '@img/logo_m.svg';
import Language_ICO from '@img/ic_language.svg';
import Polygon_ICO from '@img/ic_polygon.svg'

const Header = () => {

    const languageRef: RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);
    const [isMenu, setIsMenu] = useState(false);
    const [isLang, setIsLang] = useState(false);
    const { t, i18n } = useContext(UserLanguages);
    let location = useLocation();

    const getPage = (url: string) => {
        let pageUrl;
        if (url === '/') pageUrl = 'HOME';
        if (url === '/specs') pageUrl = 'SPECS';
        if (url === '/about') pageUrl = 'ABOUT';

        return pageUrl;
    };

    const onMobileMenu = useCallback(() => {
        setIsMenu(current => !current);
    }, []);

    useEffect(() => {

        const onLangMenu = (e: any) => {
            if (languageRef.current === e.target) {
                setIsLang(current => !current);
            } else {
                setIsLang(current => false);
            }
        };

        setIsMenu(false);

        window.addEventListener('click', onLangMenu);
    }, [])

    return (
        <header className={styles.container}>
            <div className={styles.mobileHeaderWrap}>
                <button className={styles.mobileBtn} onClick={onMobileMenu}>
                    <div className={`${styles.mobileBtnIco} ${!isMenu ? styles.inactive : styles.active}`}>
                        <span className={styles.bar01Wrap}></span>
                        <span className={styles.bar02Wrap}></span>
                    </div>
                </button>
                <h1 className={styles.logo}>
                    <Link to='/'>
                        <img className={styles.logoPc} src={Logo} alt="로고" />
                        <img className={styles.logoMo} src={LogoMobile} alt="로고" />
                    </Link>
                </h1>
            </div>
            <div className={`${styles.menuWrap} ${isMenu ? styles.active : ''}`}>
                <nav className={styles.nav}>
                    <Link
                        className={`${styles.home} ${getPage(location.pathname) === 'HOME' ? styles.active : ''}`}
                        to='/'
                        onClick={() => setIsMenu(false)}
                    >
                        {t('menu_home')}
                    </Link>
                    <Link
                        className={`${styles.specs} ${getPage(location.pathname) === 'SPECS' ? styles.active : ''}`}
                        to='/specs'
                        onClick={() => setIsMenu(false)}
                    >
                        {t('menu_specs')}
                    </Link>
                    <Link
                        className={`${styles.about} ${getPage(location.pathname) === 'ABOUT' ? styles.active : ''}`}
                        to='/about'
                        onClick={() => setIsMenu(false)}
                    >
                        {t('menu_about')}
                    </Link>
                    <div
                        ref={languageRef}
                        className={styles.language}
                    >
                        <img
                            className={styles.languages_ico}
                            src={Language_ICO}
                            alt="언어 아이콘"
                        />
                        <div className={styles.language_text}>
                            {t(`language_${String(i18n.language).slice(0, 2)}`)}
                        </div>
                        <img
                            className={`${styles.polygon_ico} ${isLang ? styles.active : ''}`}
                            src={Polygon_ICO}
                            alt="드롭 다운 아이콘"
                        />
                        <LanguagesBtn isHover={isLang} onMobileMenu={onMobileMenu} />
                    </div>
                </nav>
            </div>
        </header>
    );
};

export default React.memo(Header);