import React, { useState, useCallback } from 'react';

// COMPONENT
import { CardItem } from '@/component/component';

// // STYLES
import styles from '@styles/about/About01Element.module.css';

// ASSETS
import AboutCardImg01 from '@img/about_card01.jpg';
import AboutCardImg02 from '@img/about_card02.jpg';

// TYPE
import { HomeCard, HomeCardVal } from '@/typeList';

const CardItemInfo:HomeCard = [
    {
        className: 'about_card01',
        textContent:
            [['about_card01_title'], [
                ['about_card01_title'],
                ['about_card01_text01']
            ]],
        mediaType: 'IMAGE',
        mediaPC: [ AboutCardImg01 ],
        mediaMO: [ AboutCardImg01 ],
    },
    {
        className: 'about_card02',
        textContent:
            [['about_card02_title'], [
                ['about_card02_title'],
                ['about_card02_text01']
            ]],
        mediaType: 'IMAGE',
        mediaPC: [ AboutCardImg02 ],
        mediaMO: [ AboutCardImg02 ],
    },
]

const About01Element = () => {

    const [currentIndex, setCurrentIndex] = useState(-1);

    const setIndex = useCallback((index:number) => {
        return index === currentIndex ? true : false
    }, [currentIndex])

    return (
        <div className={styles.container}>
            {CardItemInfo.map((item:HomeCardVal, index:number) => (
                <CardItem 
                  key={index}
                  content={item}
                  index={index}
                  isShow={setIndex(index)}
                  setCurrentIndex={setCurrentIndex}
                />
            ))}
        </div>
    );
};

export default React.memo(About01Element);