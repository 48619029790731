import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide} from 'swiper/react'
import { debounce } from 'lodash'

// import required modules
import { Autoplay } from "swiper";

// STYLES
import 'swiper/css';
import styles from '@styles/home/HomeSlides.module.css';

// ASSETS
import ItemImg01 from '@img/img-01.jpg';
import ItemImg02 from '@img/img-02.jpg';
import ItemImg03 from '@img/img-03.jpg';
import ItemImg04 from '@img/img-04.jpg';
import ItemImg05 from '@img/img-05.jpg';

const itemInfo = [ItemImg01, ItemImg02, ItemImg03, ItemImg04, ItemImg05];


const HomeSlides = () => {
    const [preView, setPreView] = useState(4)

    useEffect(() => {
        const cardPerView = debounce(() => {

            if (window.innerWidth <= 1100) {
                setPreView(3);
            } else {
                setPreView(4);
            };
        }, 200);

        cardPerView();

        window.addEventListener('resize', cardPerView);

        return () => {
            window.removeEventListener('resize', cardPerView);
        };

    }, []);

    return (
        <Swiper
            className={styles.container}
            spaceBetween={22}
            slidesPerView={preView}
            loop={true}
            autoplay={{
                delay: 2500,
                disableOnInteraction: false
              }}
            modules={[ Autoplay ]}
        >
            {itemInfo.map((item, idx) => (
                <SwiperSlide
                  key={idx}
                  className={styles.item}
                >
                    <img src={item} alt="이미지" />
                </SwiperSlide>
            ))}
        </Swiper>
    );
};

export default React.memo(HomeSlides);
