import React, { useCallback, useContext, useRef, RefObject, useEffect } from 'react';

// GLOBAL VALUE
import { UserLanguages } from '@/ContextVal';

// COMPONENT
import { CardItemChild } from '@/component/component';

// STYLES
import styles from '@styles/common/CardItem.module.css';

// FUNCTION
import { deviceType } from '@/calculate'

// TYPE
import { HomeCardVal } from '@/typeList';

interface CardProps {
    content: HomeCardVal;
    index: number;
    isShow: boolean;
    setCurrentIndex: (index:number) => void;
    cardNum?: boolean;
}

const CardItem = ({content, index, isShow, setCurrentIndex, cardNum}:CardProps) => {
    const { t, i18n } = useContext(UserLanguages);
    const mediaRef:RefObject<HTMLVideoElement> = useRef<HTMLVideoElement>(null);

    const onMoreButtonClick = (e:any, index:any, isShow:any) => {
        if(isShow) {
            setCurrentIndex(-1);
        } else {
            setCurrentIndex(index);
        };
    };

    const isDevicePc = () => {
        return deviceType() === "PC" && window.innerWidth > 912;
    };

    useEffect(() => {

        const playVideo = () => {

            if(cardNum) {
                if(!mediaRef.current) return;
                mediaRef.current.play();
            };
    
        };
    
        playVideo();

    }, [cardNum]);
    

    const descRender = useCallback(() => {

        let renderData;

        if (!isShow) {
            renderData = content.textContent[0].map((item: string | string[], idx: number) => (
                <div
                    key={idx}
                    className={`card-front ${item}`}
                >
                    {t(item)}
                </div>
            ));
        } else {
            renderData = content.textContent[1].map((item: string | string[], idx: number) => (
                <CardItemChild
                    key={idx}
                    item={item}
                />
            ));
        };

        return renderData;
    }, [isShow]);
    
    const mediaRender = useCallback(() => {

        let renderData;

        if (content.mediaType === 'VIDEO') {
            renderData = (
                <video 
                  ref={mediaRef}
                    poster={
                        isDevicePc()
                            ?
                            content.mediaPC[1]
                            :
                            content.mediaMO[1]
                    }
                  muted
                  playsInline 
                  preload='auto'
                >
                    <source
                        src={
                            `${isDevicePc()
                                ?
                                content.mediaPC[0]
                                :
                                content.mediaMO[0]
                            }#t=0.001`
                        }
                    />
                </video>
            );
        } else {
            renderData = (
                <img 
                    src={
                        isDevicePc()
                            ?
                            content.mediaPC[0]
                            :
                            content.mediaMO[0]
                    } 
                  alt="설명 이미지" 
                />
            );
        };

        return renderData;
    }, []);


    return (
        <div 
          className={`${styles.container} ${content.className} ${String(i18n.language).slice(0, 2) !== 'ko' ? styles.otherLang : ''}`}
            onClick={(e) => { 
                if (!isShow) { onMoreButtonClick(e, index, isShow) } 
            }}
        >
            <div className={`${styles.textWrap} ${!isShow ? '' : styles.descWrap}`}>
                <div className={styles.wrapInner}>
                    {descRender()}
                </div>
            </div>
            <div className={`${styles.mediaWrap}  ${!isShow ? '' : styles.blur}`}>
                {mediaRender()}
            </div>
            <button 
              className={`${styles.cardBtn} ${!isShow ? '' : styles.inactive}`} 
              onClick={(e) => onMoreButtonClick(e, index, isShow) }>
                <span></span>
            </button>
        </div>
    )
};

export default React.memo(CardItem);
