import React, { useContext } from 'react'
import { Link } from 'react-router-dom';

// GLOBAL VALUE
import { UserLanguages } from '@/ContextVal';

// STYLES
import styles from '@styles/common/NotFound.module.css';

const NotFond = () => {
    const { t } = useContext(UserLanguages);

    return (
        <div className={styles.container}>
            <div className={styles.innerWrap}>
                <h3>
                    {t('not_found_title')}
                </h3>
                <p>
                    {t('not_found_text01')}
                </p>
                <Link to='/'>
                    <div>
                        <span></span>
                    </div>
                    <span>
                        {t('not_found_text02')}
                    </span>
                </Link>
            </div>
        </div>
    );
};

export default NotFond;