import React, { useContext, useEffect, useRef, RefObject, useState } from 'react';

// GLOBAL VALUE
import { UserLanguages } from '@/ContextVal';

// STYLES
import styles from '@styles/home/Home00Element.module.css';

// FUNCTION
import { calculate, progress, deviceType } from '@/calculate'

// TYPE
import { Props } from '@/typeList';

const videoImages:Array<HTMLImageElement> = [];
const totalImagesCount:number = 121;

const currentFrame = (i:number) => (
    `https://images.yeoljaeyo.com/20220707/main_00${i.toString().padStart(3, '0')}.jpg`
  );


  const preloadImages = () => {

    for (let i = 0; i < totalImagesCount; i++) {
        const img = new Image();
        img.src = currentFrame(i);
        videoImages.push(img);
    };

};

preloadImages();

const Home00Element = React.forwardRef(({componetState, prevElmHeight}:Props, ref:any) => {

    const { t, i18n } = useContext(UserLanguages);
    const canvasRef:RefObject<HTMLCanvasElement> = useRef<HTMLCanvasElement>(null);

    const canvasAniVal:any = {
        elm01_OP_in: { start: 0.1, end: 0.2, type: "ease-in-out", min:0, max: 1 },
        elm01_OP_out: { start: 0.5, end: 0.56, type: "ease-in-out", min:1, max: 0 },
        elm01_PT_in: { start: 0.1, end: 0.3, type: "ease-in-out", min:25, max: 0 },
        elm01_PT_out: { start: 0.5, end: 0.55, type: "ease-in-out", min:0, max: -5 },
        elm02_OP_in: { start: 0.6, end: 0.7, type: "ease-in-out", min:0, max: 1 },
        elm02_PT_in: { start: 0.6, end: 0.8, type: "ease-in-out", min:25, max: 0 },
    };

    const [renderElm, setRenderElm] = useState({
        elm01_OP: 0,
        elm01_PT: 0,
        elm02_OP: 0,
        elm02_PT: 0,
    });

    
    useEffect(() => {
       
        if (!componetState) return;

        const canvas = canvasRef.current;
        const ctx = canvas?.getContext('2d');
        let rafId:number;

        const updateImage = (index:number) => {
            const img = videoImages[index];
            
            const wRatio = window.innerWidth / img.width;
            const hRatio = window.innerHeight / img.height;
            const ratio = Math.max(hRatio, wRatio);
            const startX = (window.innerWidth  - img.width * ratio) / 2;
            const startY = (window.innerHeight - img.height * ratio) / 2;
            ctx?.drawImage(img, startX, startY, img.width * ratio, img.height * ratio);
        };

        const currentFrameIndex = (useProgress:number) => {
            const frameIndex = Math.min(
                totalImagesCount -1,
                Math.ceil(useProgress * totalImagesCount)
              );
              return frameIndex;
        }


        const animation = () => {
            const useProgress = progress(ref, prevElmHeight);

            rafId = requestAnimationFrame(() => updateImage(currentFrameIndex(useProgress)));

            // Text Animation
            if(useProgress < canvasAniVal.elm01_OP_in.end) {
                setRenderElm((data) => {
                    return {...data, elm01_OP: calculate(useProgress, canvasAniVal.elm01_OP_in)};
                });
            } else {
                setRenderElm((data) => {
                    return {...data, elm01_OP: calculate(useProgress, canvasAniVal.elm01_OP_out)};
                });
            }

            if(useProgress < canvasAniVal.elm01_PT_in.end) {
                setRenderElm((data) => {
                    return {...data, elm01_PT: calculate(useProgress, canvasAniVal.elm01_PT_in)};
                });
            } else {
                setRenderElm((data) => {
                    return {...data, elm01_PT: calculate(useProgress, canvasAniVal.elm01_PT_out)};
                });
            }

            if(useProgress < canvasAniVal.elm02_OP_in.end) {
                setRenderElm((data) => {
                    return {...data, elm02_OP: calculate(useProgress, canvasAniVal.elm02_OP_in)};
                });
            } else {
                setRenderElm((data) => {
                    return {...data, elm02_OP: 1};
                });
            }

            if(useProgress < canvasAniVal.elm02_PT_in.end) {
                setRenderElm((data) => {
                    return {...data, elm02_PT: calculate(useProgress, canvasAniVal.elm02_PT_in)};
                });
            }
        };

        const reSizeHandle = () => {
            const devicePixelRatio = window.devicePixelRatio ?? 1;
            const canvasWidth = window.innerWidth;
            const canvasHeight = window.innerHeight;
            if(!canvas) return;
            canvas.style.width = canvasWidth + 'px';
            canvas.style.height = canvasHeight + 'px';

            canvas.width = canvasWidth * devicePixelRatio;
            canvas.height = canvasHeight * devicePixelRatio;
            ctx?.scale(devicePixelRatio, devicePixelRatio);
        };

        const init = () => {
            reSizeHandle();
            animation();
        }

        
        init();
        if (componetState) {
            if (deviceType() === "PC" && window.innerWidth > 912) {
                window.addEventListener('resize', init);
            }
            window.addEventListener('load', init);
            window.addEventListener('scroll', animation);
        } else {
            window.removeEventListener('resize', init);
            window.removeEventListener('scroll', animation);
        }


        return () => {
            // 언마운트
            cancelAnimationFrame(rafId);
            window.removeEventListener('resize', init);
            window.removeEventListener('load', init);
            window.removeEventListener('scroll', animation);
        }

    }, [componetState, prevElmHeight]);


    return (
        <div 
          ref={ref} 
          className={`${styles.container} ${String(i18n.language).slice(0, 2) !== 'ko' ? styles.otherLang : ''}`}
        >
            <div className={styles.innerWrap}>
            <div className={styles.canvasText}>
                <h3 
                  className={styles.text01}
                  style={{opacity: `${renderElm.elm01_OP}`, transform: `translate3d(0, ${renderElm.elm01_PT}%, 0)`}}
                >
                        {t('home_elm00_text01')}
                        <br/ > 
                        {t('home_elm00_text02')}
                    </h3>
                </div>
                <div className={styles.canvasText}>
                    <h3
                      className={styles.text02}
                      style={{opacity: `${renderElm.elm02_OP}`, transform: `translate3d(0, ${renderElm.elm02_PT}%, 0)`}}
                    >
                        {t('home_elm00_text03')}
                        <br/ > 
                        <span>
                            {t('home_elm00_text04')}
                        </span>
                    </h3>
                </div>
                <canvas
                    ref={canvasRef}
                    className={styles.canvas}
                >
                </canvas>
            </div>
        </div>
    );
});

export default React.memo(Home00Element);
